import React from "react";

export const features = [
  {
    name: "Theme & Plugin Center",
    description:
      "Install new ones or manage old ones, it's incredibly fast and convenient.",
    icon: require("line-awesome/svg/plug-solid.svg"),
  },
  {
    name: "Global Search",
    description:
      "Search for posts in a simple way, also you can search posts that use some block or shortcode.",
    icon: require("line-awesome/svg/compass-solid.svg"),
  },
  {
    name: "Perform Updates",
    description:
      "A special section with information and command to run updates will help you to perform it fast and smooth.",
    icon: require("line-awesome/svg/hourglass-solid.svg"),
  },
  {
    name: "Developer-Friendly",
    description:
      "Add your own commands, It's a platformer. You can develop extensions and add new commands and actions.",
    icon: require("line-awesome/svg/code-solid.svg"),
    // icon: require("line-awesome/svg/socks-solid.svg"),
  },
  {
    name: "Keyboard Shortcuts",
    description:
      "Navigate through the list of commands by using up and down keys. Use the keyboard for quick control and hotkeys for access.",
    icon: require("line-awesome/svg/keyboard-solid.svg"),
  },
  {
    name: "Fast access to setting",
    description:
      "Change settings without going to the page. We develop special commands for quick setup.",
    icon: require("line-awesome/svg/brush-solid.svg"),
  },
  {
    name: "Usefull actions",
    description:
      "We are constantly adding new commands that can help you work more comfortably.",
    icon: require("line-awesome/svg/toolbox-solid.svg"),
  },
  {
    name: "Quick Links",
    description:
      "Add links to documentation, your agency site or resources for faster access.",
    icon: require("line-awesome/svg/hand-pointer-solid.svg"),
  },
  {
    name: "Premium & Third-party plugins support",
    description:
      "It automatically shows new menu items and we are adding special commands for popular products.",
    icon: require("line-awesome/svg/handshake-solid.svg"),
  },
  {
    name: "Automatic Barista Updates",
    description: "You will automatically recieve updates.",
    icon: require("line-awesome/svg/sync-alt-solid.svg"),
  },
  {
    name: "Translation Ready",
    description:
      "It uses translated strings from Core and another plugins, but your can help with translation.",
    icon: require("line-awesome/svg/language-solid.svg"),
  },
]

export const Features = () => (
  <div className="bg-gradient-to-r from-sky-800 to-blue-700">
    <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
      <h2 className="text-3xl font-extrabold text-white tracking-tight">
        More features and endless possibilities
      </h2>
      <p className="mt-4 max-w-3xl text-lg text-sky-200">
        Some features in development now, and you can suggest your own features
        or help with feedback.
      </p>
      <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
        {features.map(feature => (
          <div key={feature.name}>
            <div>
              <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                <feature.icon className="h-8 w-8 text-white fill-white" />
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-lg font-medium text-white">{feature.name}</h3>
              <p className="mt-2 text-base text-sky-200">
                {feature.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);
