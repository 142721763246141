import { SparklesIcon } from "@heroicons/react/outline"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { Features } from "./Features"
import { JoinBanner } from "./JoinBanner"


const Easy = () => (
  <div className="relative bg-gray-50 pt-16 overflow-hidden sm:pt-24 lg:pt-32">
    <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
      <div>
        <h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">
          Best UX
        </h2>
        <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          It's as easy to start and use
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
          Install the plugin, press the <span className="kbd">`</span>,{" "}
          <span className="kbd">cmd+B</span>,{" "}
          <span className="kbd">ctrl+B</span> hotkey, and you get access to all
          the features at once. Continue using keyboard for better productivity.
        </p>
      </div>
      <div className="mt-12">
        <video
          className={"inline w-[1100px]"}
          src={require("./../images/demo1.mp4").default}
          loop
          autoPlay
          muted={true}
          playsInline
        />
      </div>
    </div>
  </div>
)

export const Index = () => {
  return (
    <>
      <div className="relative">
        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
            <div className="absolute inset-0">
              <StaticImage
                className="h-full w-full object-cover"
                src={
                  "https://images.unsplash.com/photo-1622675363311-3e1904dc1885?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80"
                }
                alt="People working on laptops"
              />

              <div className="absolute inset-0 bg-gradient-to-r from-sky-700 to-blue-700 mix-blend-multiply" />
            </div>
            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <h1 className="text-center font-extrabold tracking-tight text-5xl sm:text-6xl lg:text-7xl">
                <span className="block text-white">Boost productivity</span>
                <span className="block text-blue-200 my-4 font-extrabold text-3xl sm:text-4xl lg:text-5xl">
                  Supercharge your WordPress site
                </span>
              </h1>
              <p className="mt-6 max-w-lg mx-auto text-center text-xl text-blue-50 sm:max-w-3xl">
                Barista is a blazingly fast, totally extendable command
                launcher. It lets you go your routine tasks faster, search
                posts, control your site, and much more.
              </p>
              <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                  <a
                    href="/join"
                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-blue-700 bg-white hover:bg-blue-50 sm:px-8"
                  >
                    Get the Plugin
                  </a>
                  <AnchorLink
                    href="#features"
                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-opacity-70 sm:px-8"
                  >
                    Features
                  </AnchorLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Easy />

      <div id="features" className="relative pt-16 pb-32 overflow-hidden">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"
        />
        <div className="relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div>
                  <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-sky-600 to-blue-600">
                    {React.createElement(
                      require("line-awesome/svg/magic-solid.svg"),
                      {
                        className: "h-8 w-8 fill-white",
                        "aria-hidden": "true",
                      }
                    )}
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    Access Menu using Fuzzy Search
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Fuzzy Search will help you get instant access to menus and
                    commands with fewer keystrokes. Instant operation without
                    delay will add even more speed to your work.
                  </p>
                </div>
              </div>
              {/* <div className="mt-8 border-t border-gray-200 pt-6">
                <blockquote>
                  <div>
                    <p className="text-base text-gray-500">
                      &ldquo;TODO Text&rdquo;
                    </p>
                  </div>
                  <footer className="mt-3">
                    <div className="flex items-center space-x-3">
                      <div className="flex-shrink-0">
                        <img
                          className="h-6 w-6 rounded-full"
                          src={require("./../images/girl.jpg").default}
                          alt="Marcia Hill, Digital Marketing Manager"
                        />
                      </div>
                      <div className="text-base font-medium text-gray-700">
                        Name, Manager
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div> */}
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                  src={require("./../images/fuzzy.png").default}
                  alt="Fuzzy Search"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-24">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div>
                <div>
                  <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-sky-600 to-blue-600">
                    <SparklesIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    Any theme and plugin support
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Uses the capabilities of the WordPress Core to get available
                    commands. Also, the source code of popular plugins allows us
                    to add more possibilities to Barista.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                  src={require("./../images/any.png").default}
                  alt="Any Plugin support"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Features />

      {/* <Stats /> */}

      {/* <Faqs /> */}

      <div id="join" className="relative sm:py-12 lg:py-24 lg:py-32">
        <div className="absolute inset-0 flex flex-col" aria-hidden="true">
          <div className="flex-1 bg-gray-50" />
          <div className="flex-1" />
        </div>
        <JoinBanner joinButton />
      </div>
    </>
  )
}
